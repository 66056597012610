import React, { useEffect, useState } from "react";
import axios from "axios";
import he from "he"; // Library to decode HTML entities

// Helper function: Strip all HTML tags and decode HTML entities
const cleanAndTruncateText = (htmlString, length) => {
  // Use DOMParser to parse HTML and extract plain text
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(he.decode(htmlString), "text/html");
  const plainText = parsedHtml.body.textContent || "";

  // Truncate the text to specified length
  if (plainText.length <= length) return plainText;
  return plainText.substring(0, length).trim() + "...";
};

const BlogPostsInfo = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Retrieve DNN Context
  const userName = window.dnnContext?.userName || "Guest";
  const userEmail = window.dnnContext?.userEmail || "No email";
  const pageName = window.dnnContext?.pageName || "Unknown Page";
  const portalId = window.dnnContext?.portalId || "Unknown portal";
  const userRoles = window.dnnContext?.userRoles || "No role";
  // const moduleTitle = window.dnnContext?.moduleTitle || "No title";
  const moduleTitle = "Info" || "No title";
  const moduleHeader = window.dnnContext?.moduleHeader || "No header";
  const moduleFooter = window.dnnContext?.moduleFooter || "No footer";

  // Fetch data from API
  // useEffect(() => {
  //   axios
  //     .get("/DesktopModules/CustomAPI/API/BlogPosts/GetRecentBlogPosts?mh={moduleTitle}&mf={moduleTitle}&mt={moduleTitle}")
  //     .then((response) => {
  //       setPosts(response.data);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching blog posts:", error);
  //       setError("A apărut o eroare la încărcarea datelor.");
  //       setIsLoading(false);
  //     });
  // }, []);
  // Fetch data from API
useEffect(() => {
  const url = `/DesktopModules/CustomAPI/API/BlogPosts/GetBP?mh=${moduleHeader}&mf=${moduleFooter}&mt=${moduleTitle}`;

  axios
    .get(url)
    .then((response) => {
      setPosts(response.data);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching blog posts:", error);
      setError("Loading error. Please try again later.");
      setIsLoading(false);
    });
}, [moduleHeader, moduleFooter, moduleTitle]);


  return (
    <div className="container">
      {/* Header Template */}
      <div className="row mt-4">
        <div className="col-lg-12 text-center">
          <h2 className="font-weight-normal text-6 mt-1 mb-2">
            Lastest <strong className="font-weight-extra-bold">{moduleTitle}</strong>
          </h2>
          {/* Display DNN Context */}
          {/* <p>
            <strong>Utilizator:</strong> {userName} <br />
            <strong>Email:</strong> {userEmail} <br />
            <strong>Pagină:</strong> {pageName} <br />
            <strong>portalId:</strong> {portalId} <br />
            {window.dnnContext.portalId === 1 && (
            <p>
            <strong>Roluri:</strong> {userRoles}
            </p>
            )}
            
          </p> */}
        </div>
      </div>

      {/* Main Content */}
      {isLoading ? (
        <div className="text-center">Loading ...</div>
      ) : error ? (
        <div className="text-center text-danger">{error}</div>
      ) : posts.length > 0 ? (
        <div className="row recent-posts pb-4 mb-5">
          {posts.map((post) => {
            const cleanSummary = cleanAndTruncateText(post.Summary, 150);

            return (
              <div key={post.ContentItemId} className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                <article>
                  <div className="row">
                    {/* Date */}
                    <div className="col-auto pr-0">
                      <div className="date">
                        <span className="day text-color-dark font-weight-extra-bold">
                          {new Date(post.PublishedOnDate).getDate()}
                        </span>
                        <span className="month bg-color-secondary font-weight-semibold text-color-light text-1">
                          {new Date(post.PublishedOnDate).toLocaleString("default", {
                            month: "short",
                          })}
                        </span>
                      </div>
                    </div>

                    {/* Content */}
                    <div className="col pl-1">
                      <h4 className="line-height-3 text-4">
                        <a href={`/News/Post/${post.ContentItemId}`} className="text-dark">
                          {post.Title}
                        </a>
                      </h4>
                      <p className="line-height-5 pr-3 mb-1">{cleanSummary}</p>
                      {/* <a
                        className="btn btn-arrow-effect-1 bg-transparent text-tertiary border-0 p-0 me-3"
                        href={`/Blog/Post/${post.ContentItemId}`}
                      >
                        <strong>MORE ...</strong>
                        <i className="fas fa-chevron-right text-2 pl-2"></i>
                      </a> */}
                      <a href={`/News/Post/${post.ContentItemId}`} class="btn btn-arrow-effect-1 bg-transparent text-tertiary border-0 p-0 me-3">More <i class="fas fa-arrow-right ms-2"></i></a>
                    </div>
                  </div>
                </article>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-12 text-center">
            <i className="fa fa-warning" style={{ fontSize: "5em", display: "block" }}></i>
            <h3>Bummer! Nothing here...</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPostsInfo;
