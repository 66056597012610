import React from "react";
import BlogPostsInfo from "./components/BlogPostsInfo";
import BlogPostsEvents from "./components/BlogPostsEvents";
import BlogPostsNews from "./components/BlogPostsNews";
import BlogPostsNewsletter from "./components/BlogPostsNewsletter";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";

function App() {
  return (
    <div className="App">
      <BlogPostsEvents />
      <BlogPostsNews />
      <BlogPostsInfo />
      <BlogPostsNewsletter />
    </div>
  );
}

export default App;
